import React, {Component} from "react";

export default class CV extends Component
{

    render()
    {
        return <iframe width="100%" height="100%" src="https://damian.laczak.net.pl/resume.pdf" />
    }

}